import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './AreaClienteInput.css';

export default function AreaClienteInput() {
  const [expanded, setExpanded] = useState(false);
  const [codeStep, setCodeStep] = useState(false);
  const [email, setEmail] = useState('');
  const [codigoAcesso, setCodigoAcesso] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const onClickEnviarEmail = async () => {
    setCodeStep(true);
    setSuccessMessage(`Se o email estiver cadastrado, um código de acesso foi enviado para: ${email}.`);
  };

  const onClickEnviarCodigo = async () => {
    console.log('Código digitado:', codigoAcesso);
  };

  const onCloseLogin = () => {
    setExpanded(false);
    setCodeStep(false);
    setSuccessMessage('');
    setEmail('');
    setCodigoAcesso('');
  };

  return (
    <div className="area-cliente-minimal">
      <div className={`area-cliente-wrapper ${expanded || codeStep ? 'expanded' : ''}`}>
        <AnimatePresence mode="wait">
          {!expanded && !codeStep && (
            <motion.button
              key="botaoAreaCliente"
              className="area-cliente-button-minimal"
              onClick={() => setExpanded(true)}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              Área Cliente
            </motion.button>
          )}

          {expanded && !codeStep && (
            <motion.div
              key="emailStep"
              className="area-cliente-form-row"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <button className="area-cliente-cancel-btn" onClick={onCloseLogin}>
                ✕
              </button>
              <input
                type="email"
                placeholder="Seu email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="area-cliente-input"
              />
              <button className="area-cliente-send-btn" onClick={onClickEnviarEmail}>
                ➜
              </button>
            </motion.div>
          )}

          {expanded && codeStep && (
            <motion.div
              key="codigoStep"
              className="area-cliente-form-row"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <button className="area-cliente-cancel-btn" onClick={onCloseLogin}>
                ✕
              </button>
              <input
                type="text"
                placeholder="Código de acesso"
                value={codigoAcesso}
                onChange={(e) => setCodigoAcesso(e.target.value)}
                className="area-cliente-input"
              />
              <button className="area-cliente-send-btn" onClick={onClickEnviarCodigo}>
                ➜
              </button>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Mensagem de sucesso fora do wrapper */}
      {successMessage && (
        <motion.div
          className="area-cliente-mensagem-sucesso"
          initial={{ opacity: 0, y: -5 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -5 }}
          transition={{ duration: 0.3 }}
        >
          {successMessage}
        </motion.div>
      )}
    </div>
  );
}