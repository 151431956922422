// CardCompra.jsx

import React, { useEffect, useState, useRef } from "react";
import ListTileItem from "./ListTileItem";
import BotaoComprar from "./BotaoComprar";
import InputPersonalizado from "./InputPersonalizado";
import { MdEmail } from "react-icons/md";
import './CardCompra.css'
import CardValorTotal from "./CardValorTotal";
import SaudacoesCliente from "./SaudacoesCliente";
import PromptInput from "./PromptInput";
import { motion, AnimatePresence } from "framer-motion";
import CardPagamento from "./CardPagamento"; // Importando o novo componente
import CardItensComprados from "./CardItensComprados";

export default function CardCompra({ lista_itens }) {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [itens, setItens] = useState(lista_itens);
  const [codigoInserido, setCodigoInserido] = useState('')
  const [inputValue, setInputValue] = useState("");
  const [mensagem, setMensagem] = useState('');
  const [cupom, setCupom] = useState("");
  const [nome, setNome] = useState("");
  const [desconto, setDesconto] = useState(0);
  const [cupomSalvo, setCupomSalvo] = useState("");
  const [email, setEmail] = useState("");
  const [tipoCupom, setTipoCupom] = useState("");
  const [itensSelecionados, setItensSelecionados] = useState([1]);
  const [descontoReaisMensagem, setDescontoReaisMensagem] = useState("");
  const [descontoReais, setDescontoReais] = useState(0);
  const [mensagemErro, setMensagemErro] = useState("");
  const [etapa, setEtapa] = useState(1); 
  const [saudacaoEspecial, setSaudacaoEspecial] = useState(null);
  const [loading, setLoading] = useState(false); // Estado para indicar carregamento
  const [sucesso, setSucesso] = useState(null); // Estado para mensagem de sucesso
  const [erro, setErro] = useState(null); // Estado para mensagem de erro
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [codigoAcesso, setCodigoAcesso] = useState('')
  const [mensagemCompraRequest, setMensagemCompraRequest] = useState(null) // Estado para armazenar detalhes do pagamento

  const onClickRecovery = () => {
    setEtapa(4)
  }

  const [errors, setErrors] = useState({}); // Estado de validação

  const lastQueriedTextRef = useRef("");
  const lastSaudacaoRef = useRef(null);
  const distinctQueriesCountRef = useRef(0);
  const verifyTimeoutRef = useRef(null); // Timer 8 segundos
  const suggestions = [
    "Treino em Casa ou Academia?",
    "Tem alergia a algum alimento ou não suporta certos alimentos?",
    "Peso e altura ajudam a sermos mais assertivos!",
    "Preferência de horário para treino?",
    "Objetivo principal: emagrecer, hipertrofiar, manter?",
    "Nível de atividade física atual?",
    "Tem alguma restrição médica?",
    "Consome suplementos? Quais?"
  ];

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const atualizaDesconto = () => {
    const itensAtualizados = lista_itens.map((item_requisicao) => {
      let valorItemCalculado = parseFloat(item_requisicao.valor_off);
      if (desconto > 0 && (tipoCupom === 0 || tipoCupom === item_requisicao.id_item)) {
        valorItemCalculado -= (valorItemCalculado * desconto) / 100;
      }
      return {
        ...item_requisicao,
        valor_off: valorItemCalculado,
      };
    });

    let descontoTotalReais = 0;
    itensSelecionados.forEach((idSelecionado) => {
      const itemOriginal = lista_itens.find((i) => i.id_item === idSelecionado);
      const itemAtualizado = itensAtualizados.find((i) => i.id_item === idSelecionado);
      if (itemOriginal && itemAtualizado) {
        const diff = parseFloat(itemOriginal.valor_normal) - itemAtualizado.valor_off;
        descontoTotalReais += diff;
      }
    });

    setItens(itensAtualizados);
    setDescontoReais(descontoTotalReais);

    let mensagem = "";
    if (desconto > 0) {
      if (tipoCupom === 0) {
        mensagem = `Você recebeu <strong>${desconto}%</strong> de desconto... <strong>${formatCurrency(descontoTotalReais)}</strong> de <em>economia</em>.`;
      } else {
        const itemEncontrado = itensAtualizados.find((item) => item.id_item === tipoCupom);
        if (itemEncontrado) {
          mensagem = `Você recebeu <strong>${desconto}%</strong> de desconto no item <strong>${itemEncontrado.descricao}</strong>, resultando em <strong>${formatCurrency(descontoTotalReais)}</strong> de <em>economia</em>.`;
        } else {
          mensagem = "Item não encontrado";
        }
      }
    }
    setDescontoReaisMensagem(mensagem);
  };

  useEffect(() => {
    atualizaDesconto();
  }, [desconto, tipoCupom, itensSelecionados, lista_itens]);

  const aplicarCupom = async (cupom) => {
    try {
      const response = await fetch(`${backendUrl}/item/validar_cupom`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cupom: cupom }),
      });

      if (!response.ok) {
        throw new Error(`Cupom ${cupom} inválido ou expirado`);
      }

      const data = await response.json();
      setDesconto(parseFloat(data.percentual_desconto));
      setCupomSalvo(cupom);
      setTipoCupom(data.tipo_cupom);
      setCupom(cupom);
      // Remover possíveis erros de cupom após aplicação bem-sucedida
      setErrors((prev) => ({ ...prev, cupom: null }));
    } catch (error) {
      setMensagemErro(`Cupom ${cupom} inválido ou expirado`);
      setErrors((prev) => ({ ...prev, cupom: 'Cupom inválido ou expirado' }));
      setTimeout(() => setMensagemErro(""), 3000);
    }
  };

  const handleEmail = (value) => {
    setEmail(value);
  };

  const handleCodigoAcesso = (value) => {
    setCodigoAcesso(value)
  }

  const toggleItemSelecionado = (itemId) => {
    if (itemId === 1) return; 
    setItensSelecionados((prevSelecionados) => {
      if (prevSelecionados.includes(itemId)) {
        return prevSelecionados.filter((id) => id !== itemId);
      } else {
        return [...prevSelecionados, itemId];
      }
    });
  };

  // Função para gerar a mensagem de compra
  const gerarMensagemCompra = async (prompt) => {
    try {
      const response = await fetch(`${backendUrl}/prompt/gerar_mensagem_compra`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prompt }), // Enviando o prompt completo
      });

      if (!response.ok) {
        const erroResposta = await response.json();
        throw new Error(erroResposta.error || `Erro ${response.status}`);
      }

      const data = await response.json();
      setMensagemCompraRequest(data.mensagemCompra);
    } catch (error) {
      console.error("Erro ao gerar a mensagem de compra:", error);
      // Opcional: Definir uma mensagem de erro ou usar a mensagem padrão
      setMensagemCompraRequest(null); // Usa a mensagem padrão no CardPagamento
    }
  };

  const handlePagamentoConfirmado = () => {
    setEtapa(5);
    // Você pode adicionar mais lógica aqui, como exibir uma mensagem de sucesso final
  };

  const handleBotaoComprar = async () => { // Tornar a função assíncrona
    if (etapa === 1) {
      const prompt = inputValue.trim(); 
      await gerarMensagemCompra(prompt);
      setEtapa(2);
    } else if (etapa === 2) {
      // Validação da etapa 2 (Email é obrigatório)
      const newErrors = {};

      if (email.trim() === '') {
        newErrors.email = 'Este campo é obrigatório.';
      } else {
        // Validação adicional do email (formato válido)
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          newErrors.email = 'Digite um email válido.';
        }
      }

      setErrors(newErrors);

      // Verificar se há erros
      if (Object.keys(newErrors).length === 0) {
        // Todas as validações passaram, prossiga com a criação do pedido
        const bodyComprar = {
          email: email,
          prompt: inputValue.trim(),
          cupom_code: cupom,
          lista_items: itensSelecionados
        };
        if (nome) {
          bodyComprar.nome = nome;
        }

        try {
          setLoading(true); // Iniciar o estado de carregamento
          setErro(null); // Resetar erro anterior
          setSucesso(null); // Resetar mensagem de sucesso

          const respCheck = await fetch(`${backendUrl}/pedido/criar_pedido`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyComprar) // Enviar diretamente o objeto
          });

          if (!respCheck.ok) { // Verificar se a resposta não foi bem-sucedida
            const erroResposta = await respCheck.json();
            throw new Error(erroResposta.message || `Erro ${respCheck.status}`);
          }

          const dados = await respCheck.json();
          setPaymentDetails(dados)
          setCodigoInserido(dados.codigo_acesso); // Armazenar detalhes do pagamento

          // Gerar a mensagem de compra usando a nova rota

          setSucesso("Pedido criado com sucesso! Verifique seu e-mail para mais detalhes.");
          setEtapa(3); // Avançar para a etapa 3
        } catch (error) {
          console.error("Erro ao criar o pedido:", error);
          setErro(error.message || "Ocorreu um erro ao criar o pedido.");
        } finally {
          setLoading(false); // Finalizar o estado de carregamento
        }
      }
    }
  };

  const variants = {
    initial: { opacity: 0, y: 20 },  // Começa um pouco abaixo
    animate: { opacity: 1, y: 0 },   // Move para a posição original
    exit: { opacity: 0, y: -20 }     // Sai um pouco acima
  };
  const onClickLogin = async () => {
    try { 
      const response = await fetch(`${backendUrl}/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, "codigo_acesso" : codigoAcesso }),
      });

      const data = await response.json();


      if (response.ok) {
        setCodigoInserido(codigoAcesso)
        setEtapa(5)
      } else {
        setMensagem(data.message || 'Erro ao enviar email');
      }
      
    } catch (error) {
      
    }
  }
  const onClickEnviarEmail = async () => {
    try {
      const response = await fetch(`${backendUrl}/login/recovery`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setMensagem(data.message);
      } else {
        setMensagem(data.message || 'Erro ao enviar email');
      }
    } catch (error) {
      setMensagem('Erro de conexão com o servidor');
    }
  };

  useEffect(() => {
    const trimmed = inputValue.trim();

    // Limpar timer se existente
    if (verifyTimeoutRef.current) {
      clearTimeout(verifyTimeoutRef.current);
      verifyTimeoutRef.current = null;
    }

    if (trimmed.length < 5) {
      // Resetar tudo
      distinctQueriesCountRef.current = 0;
      setSaudacaoEspecial(null);
      lastQueriedTextRef.current = "";
      lastSaudacaoRef.current = null;
      return; 
    }


    // >=5 chars
    if (trimmed === lastQueriedTextRef.current) {
      // Mesmo texto já consultado, não faz nada, mantém saudacao
      // Não seta timer, já que não precisa de nova consulta
      setSaudacaoEspecial(lastSaudacaoRef.current);
    } else {
      // Texto diferente do último consultado
      if (distinctQueriesCountRef.current < 3) {
        // Inicia um timer de 8s
        verifyTimeoutRef.current = setTimeout(async () => {
          // Após 8 segundos sem alterações
          if (inputValue.trim() === trimmed) {
            // Verifica se no texto existe nome
            try {
              const respCheck = await fetch(`${backendUrl}/prompt/verificar_nome_no_texto`, {
                method: "POST",
                headers: {"Content-Type":"application/json"},
                body: JSON.stringify({texto: trimmed})
              });
              if (respCheck.ok) {
                const dataCheck = await respCheck.json();
                if (dataCheck.nome) {
                  setNome(dataCheck.nome);
                  // Se existe nome e tentativas <3, gerar saudação
                  const respSaud = await fetch(`${backendUrl}/prompt/gerar_saudacao`, {
                    method: "POST",
                    headers: {"Content-Type":"application/json"},
                    body: JSON.stringify({texto: trimmed})
                  });
                  if (respSaud.ok) {
                    const dataSaud = await respSaud.json();
                    lastQueriedTextRef.current = trimmed;
                    lastSaudacaoRef.current = dataSaud.saudacao;
                    setSaudacaoEspecial(dataSaud.saudacao);
                    distinctQueriesCountRef.current += 1;
                  }
                } else {
                  // Não existe nome, sem saudacaoEspecial
                  lastQueriedTextRef.current = trimmed;
                  lastSaudacaoRef.current = null;
                  setSaudacaoEspecial(null);
                }
              }
            } catch(e) {
              console.error(e);
            }
          }
        }, 8000);

      } else {
        // Já atingiu 3 tentativas
        setSaudacaoEspecial(lastSaudacaoRef.current);
      }
    }
  }, [inputValue]);

  return (
    <div className="card-compra">
      {etapa === 1 && (<SaudacoesCliente nome={nome} saudacaoEspecial={saudacaoEspecial} />)}

      <div className="etapas-container" style={{ width: '100%' }}>
        <AnimatePresence mode="wait">
          {etapa === 1 && (
            <motion.div 
              key={`etapa-${etapa}`}
              variants={variants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.6, ease: "easeInOut" }}
              layout
              style={{ width: '100%' }}
            >
              <PromptInput 
                value={inputValue} 
                onChange={setInputValue} 
                suggestionsList={suggestions}
              />
              <BotaoComprar 
                onComprar={handleBotaoComprar} 
                label="Quero iniciar minha jornada!" 
                animated={true} 
                disabled={loading} // Desabilitar botão durante o carregamento
              />
              {/* <button onClick={onClickRecovery}>Já sou cliente Aqui</button> */}
              {loading && <p>Processando seu pedido...</p>}
              {erro && <div className="error-message">{erro}</div>}
              {sucesso && <div className="success-message">{sucesso}</div>}
            </motion.div>
          )}

          {etapa === 2 && (
            <motion.div 
              key={`etapa-${etapa}`}
              variants={variants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.6, ease: "easeInOut" }}
              layout
              style={{ width: '100%' }}
            >
              <ul>
                {itens.map((item) => (
                  <ListTileItem
                    key={item.id_item}
                    item={item}
                    isSelected={itensSelecionados.includes(item.id_item)}
                    onToggle={() => toggleItemSelecionado(item.id_item)}
                    descontoPercentual={desconto}
                    isBaseItem={item.id_item === 1} 
                  />
                ))}
              </ul>
          
              {/* Container envolvendo o valor total e os inputs */}
              <div className="valor-e-inputs-container">
                <div className="valor-column">
                  <CardValorTotal itens={itens} itensSelecionados={itensSelecionados} />
                </div>
                <div className="inputs-group">
                  <InputPersonalizado
                    upperCase={true}
                    label="Insira um Cupom de desconto ou Vale-presente"
                    textButtom="Aplicar"
                    placeholder="Digitar código"
                    onChangeValue={(value) => setCupom(value)}
                    onClickButton={() => aplicarCupom(cupom)}
                    type="text"
                    required={false} // Cupom não é obrigatório
                    showError={false} // Não precisa mostrar erro de obrigatoriedade
                  />
                  {errors.cupom && <div className="error-message-field">{errors.cupom}</div>}
                </div>
              </div>
          
              {desconto > 0 && descontoReaisMensagem && (
                <div className="cupom-message show">
                  <span className="cupom-icon">🎉</span>
                  Cupom código <strong>{cupomSalvo}</strong> aplicado com sucesso!
                  <br />
                  <span 
                    dangerouslySetInnerHTML={{ __html: descontoReaisMensagem }}
                  ></span>
                </div>
              )}

              <InputPersonalizado
                icon={<MdEmail />}
                disabled={false}
                label="Digite o seu melhor email, para envio do ACESSO"
                placeholder="email@exemplo.com.br"
                onChangeValue={(value) => handleEmail(value)}
                type="email"
                required={true}
                showError={!!errors.email}
              />
              {errors.email && <div className="error-message-field">{errors.email}</div>}

              <BotaoComprar 
                onComprar={handleBotaoComprar} 
                label="Iniciar minha Jornada agora!" 
                animated={true} 
                disabled={loading} // Desabilitar botão durante o carregamento
              />

              {loading && <p>Processando seu pedido...</p>}
              {erro && <div className="error-message">{erro}</div>}
              {sucesso && <div className="success-message">{sucesso}</div>}
            </motion.div>
          )}

          {etapa === 3 && paymentDetails && (
            <motion.div 
              key={`etapa-${etapa}`}
              variants={variants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.6, ease: "easeInOut" }}
              layout
              style={{ width: '100%' }}
            >
               <CardPagamento 
                pagamento={paymentDetails} 
                mensagemCompra={mensagemCompraRequest}
                onPagamentoConfirmado={handlePagamentoConfirmado} // Passando a função de callback
              />
            </motion.div>
          )}
         {etapa === 4 && (
            <motion.div
              key={`etapa-${etapa}`}
              variants={{
                initial: { opacity: 0, y: 50 },
                animate: { opacity: 1, y: 0 },
                exit: { opacity: 0, y: -50 },
              }}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.6, ease: 'easeInOut' }}
              layout
              style={{ width: '100%' }}
            >
              <div className="etapa-recovery">
                <h1>Já sou Cliente</h1>
                <h2>Digite o email cadastrado abaixo para entrar</h2>
                <InputPersonalizado
                  disabled={false}
                  label="Digite o email cadastrado para envio do acesso"
                  placeholder="email@exemplo.com.br"
                  onChangeValue={(value) => handleEmail(value)} // Atualiza o email
                  type="email"
                  required={true}
                  showError={!!errors.email} // Validação
                  onClickButton={onClickEnviarEmail} // Chama a função ao clicar no botão
                  textButtom="Enviar"
                />
                {mensagem && (
                  <div>
                    <p>{mensagem}</p>
                    <InputPersonalizado
                  disabled={false}
                  label="Digite o código de Acesso enviado para o email"
                  placeholder="Código de Acesso"
                  onChangeValue={(value) => handleCodigoAcesso(value)} // Atualiza o email
                  type="text"
                  required={true}
                  showError={!!errors.email} // Validação
                  onClickButton={onClickLogin} // Chama a função ao clicar no botão
                  textButtom="Entrar"
                />
                  </div>
                )}
              </div>
            </motion.div>
          )}
           {etapa === 5 && (
            <motion.div 
              key={`etapa-${etapa}`}
              variants={variants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.6, ease: "easeInOut" }}
              layout
              style={{ width: '100%' }}
            >
              <div className="etapa-final">
                <CardItensComprados 
                email={email}
                codigoAcesso={codigoInserido}
                mensagemFinal={mensagemCompraRequest}
              />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}