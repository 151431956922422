// CardItensComprados.jsx
import React, { useEffect, useState } from 'react';
import './CardItensComprados.css';

export default function CardItensComprados({ codigoAcesso, email}) {
    const [itensComprados, setItensComprados] = useState([])
    const [valorTotal , setValorTotal] = useState(0)
    const [mensagemFinal, setMensagemFinal] = useState(null)
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        const fetchItens = async () => {
          var url = `${backendUrl}/login/`
          try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body:  JSON.stringify({"codigo_acesso" : codigoAcesso, "email": email}),
              });

            const data = await response.json();
            console.log(data)
            setItensComprados(data.itens_comprados);
            setValorTotal(data.valor_total);
          } catch (err) {
            // setErro(err.message);
          } finally {
            // setCarregando(false);
          }
        };
    
        fetchItens();
      }, []);
  return (
    <div className="card-itens-comprados">
      <div className="itens-lista">
      {itensComprados && itensComprados.length > 0 && (
          <h2>Pedido #{itensComprados[0].id_pedido} efetuado e pagamento confirmado!</h2>)}
        {itensComprados && itensComprados.length > 0 ? (

          itensComprados.map((item) => (
            <div key={item.id_item} className="item">
              <div className="item-info">
                <span className="nome">{item.descricao}</span>

              </div>
              <div className="item-preco">
                <span>{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(item.valor)}</span>
              </div>
            </div>
          ))
        ) : (
          <p>Nenhum item encontrado.</p>
        )}
      </div>

      <div className="valor-total">
        <span>Total:</span>
        <span>{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(valorTotal)}</span>
      </div>

      <div className="mensagem-final">
        <div className="header">
          <p>{mensagemFinal || "Obrigado por sua compra. Aqui estão os detalhes dos itens adquiridos:"}</p>
        </div>
      </div>
    </div>
  );
}