// HomePage.jsx

import React, { useState } from 'react';
import Hero from './Hero';
import CardCompra from './CardCompra';
import Avaliacoes from './Avaliacoes';
import FAQ from './FAQ';
import Footer from './Footer';
import './HomePage.css'; // Criaremos este arquivo para estilos gerais

export default function HomePage({lista_itens}) {
  const [showCardCompra, setShowCardCompra] = useState(false);

  const handleStartJourney = () => {
    // Scroll suave para o CardCompra
    const cardCompraSection = document.getElementById('card-compra-section');
    if (cardCompraSection) {
      cardCompraSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Dados fictícios para avaliação
  const avaliacoesData = [
    {
      nome: "João Silva",
      estrelas: 5,
      comentario: "Transformou minha vida! O plano personalizado foi perfeito para meus objetivos."
    },
    {
      nome: "Maria Oliveira",
      estrelas: 4,
      comentario: "Excelente assessoria, mas poderia ter mais opções de dietas."
    },
    {
      nome: "Carlos Pereira",
      estrelas: 5,
      comentario: "A IA é incrível! Me ajudou a manter a disciplina e atingir meus resultados."
    },
    {
      nome: "João Silva",
      estrelas: 5,
      comentario: "Transformou minha vida! O plano personalizado foi perfeito para meus objetivos."
    },
    {
      nome: "Maria Oliveira",
      estrelas: 4,
      comentario: "Excelente assessoria, mas poderia ter mais opções de dietas."
    },
    {
      nome: "Carlos Pereira",
      estrelas: 5,
      comentario: "A IA é incrível! Me ajudou a manter a disciplina e atingir meus resultados."
    },
    {
      nome: "Carlos Pereira",
      estrelas: 5,
      comentario: "A IA é incrível! Me ajudou a manter a disciplina e atingir meus resultados."
    },
    {
      nome: "João Silva",
      estrelas: 5,
      comentario: "Transformou minha vida! O plano personalizado foi perfeito para meus objetivos."
    },
    {
      nome: "Maria Oliveira",
      estrelas: 4,
      comentario: "Excelente assessoria, mas poderia ter mais opções de dietas."
    },
    {
      nome: "Carlos Pereira",
      estrelas: 5,
      comentario: "A IA é incrível! Me ajudou a manter a disciplina e atingir meus resultados."
    },
    {
      nome: "Carlos Pereira",
      estrelas: 5,
      comentario: "A IA é incrível! Me ajudou a manter a disciplina e atingir meus resultados."
    },
    {
      nome: "João Silva",
      estrelas: 5,
      comentario: "Transformou minha vida! O plano personalizado foi perfeito para meus objetivos."
    },
    {
      nome: "Maria Oliveira",
      estrelas: 4,
      comentario: "Excelente assessoria, mas poderia ter mais opções de dietas."
    },
    {
      nome: "Carlos Pereira",
      estrelas: 5,
      comentario: "A IA é incrível! Me ajudou a manter a disciplina e atingir meus resultados."
    },
    {
      nome: "Carlos Pereira",
      estrelas: 5,
      comentario: "A IA é incrível! Me ajudou a manter a disciplina e atingir meus resultados."
    },
    {
      nome: "João Silva",
      estrelas: 5,
      comentario: "Transformou minha vida! O plano personalizado foi perfeito para meus objetivos."
    },
    {
      nome: "Maria Oliveira",
      estrelas: 4,
      comentario: "Excelente assessoria, mas poderia ter mais opções de dietas."
    },
    {
      nome: "Carlos Pereira",
      estrelas: 5,
      comentario: "A IA é incrível! Me ajudou a manter a disciplina e atingir meus resultados."
    }
  ];

  return (
    <div className="homepage">
      <Hero onStart={handleStartJourney} haveCupom={true} percentual={50} />
      
      {/* Seção Sobre o Produto */}
      <section className="sobre-produto container-padded">
        <h2>Sobre Nossa Assessoria</h2>
        <p>
          Nossa assessoria oferece um plano completo de dieta, nutrição e treino, personalizado com a ajuda da nossa IA inteligente, <strong>NutriAI</strong>. Com base nos seus dados e preferências, criamos um programa de 90 dias que se adapta ao seu estilo de vida, seja você um iniciante ou um atleta experiente.
        </p>
      </section>

      {/* Seção Como Funciona */}
      <section className="como-funciona container-padded">
        <h2>Como Funciona</h2>
        <div className="steps">
          <div className="step">
            <h3>1. Insira Seus Dados</h3>
            <p>Preencha informações sobre sua dieta, preferências alimentares, rotina de treinos e objetivos.</p>
          </div>
          <div className="step">
            <h3>2. Análise da IA</h3>
            <p>Nossa IA, <strong>NutriAI</strong>, processa seus dados para criar um plano personalizado para você.</p>
          </div>
          <div className="step">
            <h3>3. Receba Seu Plano</h3>
            <p>Receba um plano detalhado de 90 dias com dietas, treinos e instruções para alcançar seus objetivos.</p>
          </div>
        </div>
      </section>

      {/* Seção Depoimentos */}
      <Avaliacoes avaliacoes={avaliacoesData} />

      {/* Seção Detalhes da Oferta */}
      <section className="detalhes-oferta container-padded">
        <h2>Oferta Especial</h2>
        <p>
          Por menos de <strong>15 centavos por dia</strong>, você recebe uma assessoria completa que pode economizar até <strong>50 vezes</strong> em relação a planos tradicionais. Invista no seu bem-estar e transforme sua vida com nosso suporte especializado.
        </p>
      </section>

      {/* Seção CardCompra */}
      <section id="card-compra-section" className="container-padded">
        <CardCompra lista_itens={lista_itens} />
      </section>

      {/* Seção FAQ */}
      <FAQ />

      {/* Rodapé */}
      <Footer />
    </div>
  );
}