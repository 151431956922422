// Footer.jsx

import React from 'react';
import './Footer.css'; // Criaremos este arquivo para os estilos

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; {new Date().getFullYear()} Sua Empresa. CNPJ: 00.000.000/0001-00</p>
        <div className="footer-links">
          <a href="/sobre">Sobre Nós</a>
          <a href="/politicas">Políticas de Reembolso</a>
          <a href="/termos">Termos de Uso</a>
        </div>
      </div>
    </footer>
  );
}