// src/components/App.jsx

import React, { useEffect, useState } from "react";
import HomePage from "./components/HomePage";
import AnimatedAssistant from "./components/AnimatedAssistant"; // Importando o assistente
import './App.css';

export default function App() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [listaItens, setListaItens] = useState([]);
  const [carregando, setCarregando] = useState(true);
  const [erro, setErro] = useState(null);

  useEffect(() => {
    const fetchItens = async () => {
      try {
        console.log(backendUrl)
        const response = await fetch(`${backendUrl}/item/`);
        if (!response.ok) {
          throw new Error(`Erro: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        setListaItens(data);
      } catch (err) {
        setErro(err.message);
      } finally {
        setCarregando(false);
      }
    };

    fetchItens();
  }, [backendUrl]);

  if (carregando) {
    return <div>Carregando itens...</div>;
  }

  if (erro) {
    return <div>Erro ao carregar itens: {erro}</div>;
  }

  return (
    <div className="App">
      <HomePage lista_itens={listaItens}/>
      <AnimatedAssistant /> {/* Adicionando o Assistente Flutuante */}
    </div>
  );
}