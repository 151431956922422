// FAQ.jsx

import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import './FAQ.css'; // Criaremos este arquivo para os estilos

export default function FAQ() {
  const perguntas = [
    {
      pergunta: "Como funciona a assessoria de 90 dias?",
      resposta: "Após você inserir seus dados, nossa IA irá analisar suas informações e criar um plano personalizado de dieta, nutrição e treino para 90 dias, adaptado às suas necessidades e objetivos."
    },
    {
      pergunta: "Posso cancelar a qualquer momento?",
      resposta: "Sim, você pode cancelar sua assinatura a qualquer momento. Consulte nossa política de reembolso para mais detalhes."
    },
    {
      pergunta: "Como a IA personaliza o meu plano?",
      resposta: "A IA analisa seus dados, incluindo preferências alimentares, rotina de treinos, objetivos e restrições, para criar um plano que maximize seus resultados de forma eficiente."
    },
    // Adicione mais perguntas conforme necessário
  ];

  const [aberto, setAberto] = useState(null);

  const toggleFAQ = (index) => {
    if (aberto === index) {
      setAberto(null);
    } else {
      setAberto(index);
    }
  };

  return (
    <section className="faq">
      <h2>Perguntas Frequentes</h2>
      <div className="faq-container">
        {perguntas.map((item, index) => (
          <div key={index} className="faq-item">
            <div className="faq-pergunta" onClick={() => toggleFAQ(index)}>
              <h3>{item.pergunta}</h3>
              {aberto === index ? <FaMinus /> : <FaPlus />}
            </div>
            {aberto === index && <div className="faq-resposta"><p>{item.resposta}</p></div>}
          </div>
        ))}
      </div>
    </section>
  );
}