import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './Hero.css';
import AreaClienteInput from './AreaClienteInput';

export default function Hero({ onStart, haveCupom = false, percentual = 0 }) {
  const slides = [
    {
      type: 'image',
      src: 'assets/fitness.jpg',
      alt: 'Transformação Fitness',
      description:
        'Transforme sua vida com nosso programa personalizado de dieta, nutrição e treino.',
      duration: 7000,
    },
    {
      type: 'video',
      src: 'assets/fitness-video.mp4',
      alt: 'Transformação Fitness em Vídeo',
      description:
        'Assista a histórias inspiradoras de quem já transformou sua vida com a nossa ajuda.',
      duration: 10000,
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [fadeDirection, setFadeDirection] = useState(1);
  const slideTimeout = useRef(null);

  const [timeLeft, setTimeLeft] = useState(15 * 60); 
  const timerRef = useRef(null);

  const nextSlide = () => {
    setFadeDirection(1);
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  useEffect(() => {
    clearTimeout(slideTimeout.current);
    slideTimeout.current = setTimeout(() => {
      nextSlide();
    }, slides[currentSlide].duration);
    return () => clearTimeout(slideTimeout.current);
  }, [currentSlide]);

  useEffect(() => {
    if (haveCupom) {
      timerRef.current = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            clearInterval(timerRef.current);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timerRef.current);
  }, [haveCupom]);

  const formatTime = (seconds) => {
    const m = Math.floor(seconds / 60).toString().padStart(2, '0');
    const s = (seconds % 60).toString().padStart(2, '0');
    return `${m}:${s}`;
  };

  return (
    <section className="hero">
      <div className="area-cliente-minimal">
        <AreaClienteInput />
      </div>

      <div className="hero-slider">
        <AnimatePresence initial={false} custom={fadeDirection}>
          {slides.length > 0 && (
            <motion.div
              key={currentSlide}
              className="slide"
              custom={fadeDirection}
              variants={{
                enter: (direction) => ({
                  opacity: 0,
                  x: direction > 0 ? 100 : -100,
                }),
                center: { opacity: 1, x: 0 },
                exit: (direction) => ({
                  opacity: 0,
                  x: direction < 0 ? 100 : -100,
                }),
              }}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{ duration: 0.8 }}
            >
              <div className="media-container">
                {slides[currentSlide].type === 'image' ? (
                  <img
                    src={slides[currentSlide].src}
                    alt={slides[currentSlide].alt}
                    className="media"
                  />
                ) : (
                  <video
                    src={slides[currentSlide].src}
                    className="media"
                    autoPlay
                    muted
                    loop
                  />
                )}
              </div>
              <motion.div
                className="hero-content"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}
              >
                <h1>Transforme Sua Vida com Nossa Assessoria Personalizada</h1>
                <p>{slides[currentSlide].description}</p>
                {haveCupom ? (
                  <motion.div
                    className="cta-container"
                    initial={{ height: 'auto' }}
                    animate={{ height: 'auto' }}
                    transition={{ duration: 0.5 }}
                  >
                    <motion.button
                      className="cta-button"
                      onClick={onStart}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      Quero Iniciar Minha Jornada Agora
                    </motion.button>
                    {timeLeft > 0 && (
                      <motion.div
                        className="cupom-info"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <p>
                          <strong>{percentual}% OFF</strong> por tempo limitado! Restam{' '}
                          {formatTime(timeLeft)} minutos.
                        </p>
                      </motion.div>
                    )}
                  </motion.div>
                ) : (
                  <motion.button
                    className="cta-button"
                    onClick={onStart}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Quero Iniciar Minha Jornada Agora
                  </motion.button>
                )}
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </section>
  );
}